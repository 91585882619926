import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";

function ProductSmokingHero() {
  return (
    <Container
      component="section"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        my: 9,
      }}
    >
      <Button
        component="a"
        href="mailto:info@therockies.es?subject=Let's set up an appointment&body=Greetings! My name is _____________ I'm interested in scheduling an appointment to explore ways to enhance my English proficiency."

        sx={{
          border: "4px solid currentColor",
          borderRadius: 0,
          height: "auto",
          py: 2,
          px: 5,
        }}
      >
        <Typography variant="h4" >
          Got any questions? Need help?
        </Typography>
      </Button>

      <Button
        component="a"
        href="mailto:info@therockies.es?subject=Let's set up an appointment&body=Greetings! My name is _____________ I'm interested in scheduling an appointment to explore ways to enhance my English proficiency."

      >
        <Typography variant="subtitle1" sx={{ my: 3 }}>
          We are here to help. Get in touch!
        </Typography>
      </Button>

      <Button
        component="a"
        href="mailto:info@therockies.es?subject=Let's set up an appointment&body=Greetings! My name is _____________ I'm interested in scheduling an appointment to explore ways to enhance my English proficiency."

      >
        <LiveHelpIcon fontSize="large" color="warning" />
      </Button>
    </Container>
  );
}

export default ProductSmokingHero;
