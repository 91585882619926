import * as React from "react";
import Button from "../components/Button";
import Typography from "../components/Typography";
import ProductHeroLayout from "./ProductHeroLayout";
import {Box} from "@mui/material";

const backgroundImage = "/img/summer.jpeg";

export default function ProductHero() {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: "#7fc7d9", // Average color of the background image.
        backgroundPosition: "center",
      }}
    >
      <Box my={10} />
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{display: "none"}}
        src={backgroundImage}
        alt="increase priority"
      />
      <Typography color="inherit" align="center" variant="h2" marked="center">
        Embark on Your English Mastery Voyage!
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="h5"
        sx={{mb: 4, mt: {xs: 4, sm: 10}}}
      >
        Ready to take on the challenge? Sign up now for a free consultation and
        let's map out your path to English excellence together. Your road to
        success begins here!
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        size="large"
        component="a"
        href="mailto:info@therockies.es?subject=Let's set up an appointment&body=Greetings! My name is _____________ I'm interested in scheduling an appointment to explore ways to enhance my English proficiency."
        sx={{minWidth: 200}}
      >
        SET AN APPOINTMENT
      </Button>
    </ProductHeroLayout>
  );
}
