import * as React from "react";
import {Theme} from "@mui/material/styles";
import {SxProps} from "@mui/system";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";
import DiamondIcon from "@mui/icons-material/Diamond";
import ClockIcon from "@mui/icons-material/LockClock";
import GroupIcon from "@mui/icons-material/Group";

const item: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
};

function ProductValues() {
  return (
    <Box
      component="section"
      sx={{display: "flex", overflow: "hidden", bgcolor: "secondary.light"}}
    >
      <Container sx={{mt: 15, mb: 30, display: "flex", position: "relative"}}>
        <Box
          component="img"
          src="/static/themes/onepirate/productCurvyLines.png"
          alt="curvy lines"
          sx={{pointerEvents: "none", position: "absolute", top: -180}}
        />
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <DiamondIcon sx={{fontSize: 60}} color="secondary" />
              <Typography variant="h6" textAlign='center'sx={{my: 5}}>
                Tailored Learning Experience
              </Typography>
              <Typography variant="h5">
                {"Our one-on-one sessions ensure personalized attention"}
                {
                  ", allowing you to focus on your specific language goals and progress at your own peace."
                }
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <ClockIcon sx={{fontSize: 60}} color="secondary" />
              <Typography variant="h6" sx={{my: 5}}>
                Flexible Scheduling
              </Typography>
              <Typography variant="h5">
                {"Life is busy, and we understand that… "}
                {
                  "That's why we offer flexible scheduling options, ensuring that your English classes seamlessly fit into your weekly routine."
                }
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <GroupIcon sx={{fontSize: 60}} color="secondary" />
              <Typography variant="h6" sx={{my: 5}}>
                Engaging Group Sessions
              </Typography>
              <Typography variant="h5">
                {
                  "Join our group classes FOR FREE and engage in dynamic discussions with fellow learners. "
                }
                {"English has never been so much fun!"}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductValues;
