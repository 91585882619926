// import {useMediaQuery} from "@mui/material";
// import Mobile from "./app/Mobile";
// import Desktop from "./app/Desktop";
import New from "./app/New";

function App() {
  // const matches = useMediaQuery("(min-width:600px)");

  return <New />;
  // if (!matches) {
  //   return <Mobile />;
  // }
  // return <Desktop />;
}

export default App;
