import * as React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";


function Copyright() {
  return (
    <Box textAlign='center'>
      {"© "}
      <Link color="inherit" href="https://therockies.es">
        therockies 
      </Link>{}
      {new Date().getFullYear()}
    </Box>
  );
}

export default function AppFooter() {
  return (
    <Typography
      component="footer"
      sx={{ display: "flex", bgcolor: "white" }}
    >
      <Container sx={{ my: 8, display: "flex" }}>
        <Box width='100%'>
          <Typography variant="h6" marked="left" gutterBottom>
            Legal
          </Typography>
          <Box component="ul" sx={{ m: 0, listStyle: "none", p: 0 }}>
            <Box component="li" sx={{ py: 0.5 }}>
              <Link href="/premium-themes/onepirate/terms/">Terms</Link>
            </Box>
            <Box component="li" sx={{ py: 0.5 }}>
              <Link href="/premium-themes/onepirate/privacy/">Privacy</Link>
            </Box>
          </Box>

          <Copyright />
        </Box>
      </Container>
    </Typography>
  );
}
