import React, {useEffect} from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {CssBaseline, ThemeProvider} from "@mui/material";
import theme from "./styles/theme";
import {auth, db} from "./config/firebase";
import {ref, get, set} from "firebase/database";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const Render: React.FC = () => {
  useEffect(() => {
    auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        if (!currentUser.emailVerified) {
          throw new Error("Must verify email first");
        }

        if (!currentUser.providerData[0]?.uid) {
          throw new Error("Invalid ID");
        }

        const userRef = ref(db, "users/" + currentUser.providerData[0].uid);
        get(userRef)
          .then((response) => {
            if (!response.val()) {
              set(userRef, {uid: currentUser.providerData[0]?.uid});
            }
          })
          .catch((error) => {
            console.log("error =>", error);
          });
      } else {
        localStorage.clear();
      }
    });
  }, []);

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </React.StrictMode>
  );
};
root.render(<Render />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
